import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Check the import here
// import Home from './Pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import './App.css';
import Posts from './Pages/Posts/Posts';
import PostDetial from './Pages/Posts/post_detial/Post_detial';
import SexCategory from './Pages/Category/sexCategory/sexCategory';
import FemaleCategory from './Pages/Category/femaleCategory';
import MaleCategory from './Pages/Category/maleCategory';
import AgeFilter from './Pages/Posts/age_filter/AgeFilter';

function App() {
  return (
    <Router classname="container_app"> 
      <Navbar/>
      <Routes>
        {/* <Route path={'/'} element={<Home />} /> */}
        {/* <Route path={'/'} element={<SexCategory />} /> */}
        <Route exact path={'/'} element={<FemaleCategory />} />
        {/* <Route exact path={'/male'} element={<MaleCategory />} /> */}
        <Route path={'/post/:id/:age'} element={<Posts />}/>
        <Route path={'/postdetial/:id'} element={<PostDetial />}/>
        <Route path={'/agefilter/:id'} element={<AgeFilter />}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;