import React, { useEffect, useState } from 'react';
import styles from './category.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AddCategoryModal from '../../Modal/Category/AddCategoryModal';
import EditCategoryModal from '../../Modal/Category/EditCategoryModal';
import { CiEdit, CiTrash } from "react-icons/ci";
import DeleteCategoryModal from '../../Modal/Category/DeleteCategoryModal';
import AddSizeModal from '../../Modal/Size/AddSizeModal';

const FemaleCategory = () => {
  const [category, setCategory] = useState([]);
  const [modal, setModal] = useState(false);
  const [sizemodal, setSizeModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editdata, setEdit] = useState({});
  const [sizes, setSizes] = useState([]);

  const closeModal = () => {
    setModal(false);
    setEditModal(false);
    setDeleteModal(false);
    setSizeModal(false);
  }

  useEffect(() => {
    axios.get(`https://clothes.templateapi.xyz/categories`)
      .then((e) => setCategory(e.data.data))
      .catch((error) => alert(error));
    axios.get(`https://clothes.templateapi.xyz/size`)
      .then((e) => setSizes(e.data.data))
      .catch((error) => alert(error));
  }, []);

  return (
    <>
      {modal ? <AddCategoryModal closeModal={closeModal} /> : null}
      {sizemodal ? <AddSizeModal closeModal={closeModal} /> : null}
      {editdata && editModal ? <EditCategoryModal data={editdata} closeModal={closeModal} /> : null}
      {editdata && deleteModal ? <DeleteCategoryModal data={editdata} closeModal={closeModal} /> : null}
      <div className={styles.container_select}>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
        <button onClick={() => setModal(true)} className='add_button'>
          <img
            src={'https://cdn-icons-png.flaticon.com/512/992/992651.png'}
            alt="" />
          <p>Катигори нэмэх</p>
        </button>
        <button onClick={() => setSizeModal(true)} className='add_button'>
          <img
            src={'https://cdn-icons-png.flaticon.com/512/992/992651.png'}
            alt="" />
          <p>Хэмжээ нэмэх</p>
        </button>
      </div>
      <div style={{
        display: 'flex',
        padding: '10px',
        overflowY: 'scroll'
      }}>
        {sizes.map((e) => (
          <div className={styles.link}>
            <p>{e.name}</p>
          </div>
        ))}
      </div>
      <div className={styles.container}>
        {category.sort((a, b) => parseInt(a.test_1) > parseInt(b.test_1) ? 1 : -1).map(e => (
          <div style={{
            height: '45vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#f0f0f0',
            borderRadius: '30px'
          }}>
            <Link to={`/agefilter/${e.id}`} className={styles.link}>
              <p>{e.name}</p>
            </Link>
            <div>
              <button onClick={() => {
                setEditModal(true);
                setEdit(e);
              }} className='add_button' style={{ color: 'orange' }}>
                <CiEdit size={35} />
                <p>Катигори Засах</p>
              </button>
              <button onClick={() => {
                setDeleteModal(true);
                setEdit(e);
              }} className='add_button' style={{ color: 'red' }}>
                <CiTrash size={35} />
                <p>Катигори Устгах</p>
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default FemaleCategory
