import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import styles from './post.module.css';

export default function EditPostModal({ closeModal, data }) {
    const { id } = useParams();
    const [title, setTitle] = useState(data.title);
    const [price, setPrice] = useState(data.price);
    const [desc, setDesc] = useState(data.description);
    const [summary, setSummary] = useState(data.summary);
    const [test_2, setTest_2] = useState(data.test_2);
    const [content, setContent] = useState(data.content);
    const [files, setFiles] = useState('');
    const [contactPhone, setContactPhone] = useState(data.contactPhone);
    const [contactEmail, setContactEmail] = useState(data.contactEmail);
    const [container, setContainer] = useState(data.sex);
    const [age, setAge] = useState(data);
    const [tailbar, setTailbar] = useState(data.test_4);
    const [redirect, setRedirect] = useState(false);
    const [category, setCategory] = useState({});
    const navigate = useNavigate();
    const [sizes, setSizes] = useState(data.sizes);

    const handleSizeChange = (index, field, value) => {
        const updatedSizes = [...sizes];
        updatedSizes[index][field] = value;
        setSizes(updatedSizes);
    };

    const addSizeField = () => {
        setSizes([...sizes, { size: '', balance: 0 }]);
    };

    const removeSizeField = (index) => {
        const updatedSizes = sizes.filter((_, i) => i !== index);
        setSizes(updatedSizes);
    };



    useEffect(() => {
        axios
            .get(`https://clothes.templateapi.xyz/categories/${id}`)
            .then((res) => {
                setCategory(res.data.data);
            })
            .catch((e) => console.log(e));
    }, [id]);

    async function createNewPost(ev) {
        ev.preventDefault();

        // alert(data._id);

        const formdata = new FormData();
        formdata.append('title', title);
        // formdata.append('id', formdata._id);
        formdata.append('summary', summary);
        formdata.append('file', files[0]);
        formdata.append('category', id);
        formdata.append('price', price);
        formdata.append('description', desc);
        formdata.append('content', content);
        formdata.append('sex', container);
        formdata.append('age', 'age');
        formdata.append('contactPhone', contactPhone);
        formdata.append('contactEmail', contactEmail);
        formdata.append('test_2', test_2);
        formdata.append('test_3', title + 'z');
        formdata.append('test_4', tailbar);
        // formdata.append('isHandle', false);
        sizes.forEach((sizeObj, index) => {
            formdata.append(`sizes[${index}][size]`, sizeObj.size);
            formdata.append(`sizes[${index}][balance]`, sizeObj.balance);
        });

        try {
            const response = await fetch('https://clothes.templateapi.xyz/post/' + data._id, {
                method: 'PUT',
                body: formdata,
                credentials: 'include',
            })
                .then((e) => {
                    alert('Амжилттай нэмэгдлээ');
                    // setRedirect(true);
                })
                .catch((e) => {
                    alert(e);
                });
        } catch (error) {
            console.error('Error occurred during fetch:', error);
        }
    }
    if (redirect) {
        return <Navigate to={'/'} />;
    }


    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className="two-line">
                    <h2>{category.name} - Бараа засах</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                {/* <form onSubmit={createNewPost}> */}
                <input
                    type="text"
                    placeholder="Нэр код"
                    value={title}
                    onChange={(ev) => setTitle(ev.target.value)}
                />
                <input
                    type="text"
                    placeholder="Үнэ"
                    value={price}
                    onChange={(ev) => setPrice(ev.target.value)}
                />
                <input
                    type="text"
                    placeholder="Хувцас хадгалсан газар"
                    value={container}
                    onChange={(ev) => setContainer(ev.target.value)}
                />
                <input
                    type="text"
                    placeholder="Тоо"
                    value={content}
                    onChange={(ev) => setContent(ev.target.value)}
                />
                <input
                    type="text"
                    placeholder="Барьцаа"
                    value={desc}
                    onChange={(ev) => setDesc(ev.target.value)}
                />
                <input
                    type="text"
                    placeholder="Тайлбар хоосон үлдээж болно"
                    value={tailbar}
                    onChange={(ev) => setTailbar(ev.target.value)}
                />
                <input
                    type="text"
                    placeholder="Холбоо барих утасны дугаар"
                    value={contactPhone}
                    onChange={(ev) => setContactPhone(ev.target.value)}
                />
                {sizes.map((sizeObj, index) => (
                    <div key={index} className="size-field">
                        <input
                            type="text"
                            placeholder="Size"
                            value={sizeObj.size}
                            onChange={(ev) => handleSizeChange(index, 'size', ev.target.value)}
                        />
                        <input
                            type="number"
                            placeholder="Balance"
                            value={sizeObj.balance}
                            onChange={(ev) => handleSizeChange(index, 'balance', ev.target.value)}
                        />
                        <button type="button" style={{ color: 'red', borderColor: 'red' }} onClick={() => removeSizeField(index)}>
                            Хасах
                        </button>
                    </div>
                ))}
                <button type="button" onClick={addSizeField}>Хэмжээ нэмэх</button>
                <div>
                    <p className={styles.select_title}>5-9 нас</p>
                    <select className={styles.select} value={test_2} name="age" onChange={(e) => setTest_2(e.target.value)}>
                        <option >Насаа сонгоно уу</option>
                        <option value="true">Тийм</option>
                        <option value="false">Үгүй</option>
                    </select>
                </div>
                <div>
                    <p className={styles.select_title}>9-14 нас</p>
                    <select className={styles.select} value={summary} name="age" onChange={(e) => setSummary(e.target.value)}>
                        <option >Насаа сонгоно уу</option>
                        <option value="true">Тийм</option>
                        <option value="false">Үгүй</option>
                    </select>
                </div>
                <div>
                    <p className={styles.select_title}>15-Насанд хүрэгчид</p>
                    <select className={styles.select} value={contactEmail} name="age" onChange={(e) => setContactEmail(e.target.value)}>
                        <option >Насаа сонгоно уу</option>
                        <option value="true">Тийм</option>
                        <option value="false">Үгүй</option>
                    </select>
                </div>
                <input type="file" onChange={(ev) => setFiles(ev.target.files)} />
                {files ? <img src={URL.createObjectURL(files[0])} style={{ width: '250px', height: '250px', objectFit: 'cover' }} /> : null}
                <div className="two-line">
                    <button style={{ marginTop: "5px" }} onClick={createNewPost}>Хадгалах</button>
                    <button style={{ color: 'red', borderColor: 'red' }} onClick={closeModal}>Буцах</button>
                </div>
            </div>
        </div>
    );
}
