import 'react-quill/dist/quill.snow.css';
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from 'axios';

export default function EditCategoryModal({ closeModal, data }) {
    const [name, setName] = useState(data.name);
    const [sex, setSex] = useState(data.sex);
    // const [age, setAge] = useState('');
    const [type, setType] = useState(data.type);
    const [rankId, setRankId] = useState(data.test_1);
    // const [description, setDescription] = useState('');
    const [redirect, setRedirect] = useState(false);
    const navigate = useNavigate();

    async function createNewPost(ev) {

        ev.preventDefault();
        if (name.length > 0) {
            const respone = await axios.put('https://clothes.templateapi.xyz/categories/' + data._id, {
                name: name,
                sex: sex,
                age: 'age',
                type: type,
                test_1: rankId,
            }).then(function (e) {
                window.location.reload(false);
            }).catch(function (e) {
                alert(e);
            });
        } else {
            alert('Та мэдээллээ дутуу оруулсан байна.');
        }
    }

    if (redirect) {
        return <Navigate to={'/category'} />;
    }


    return (
        <div className='blur_container'>
            <div className={'modal'}>
                <div className="two-line">
                    <h2>Категори засах</h2>
                    <h2 onClick={closeModal} style={{ cursor: 'pointer' }}>X</h2>
                </div>
                <p>Name</p>
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                />
                <p>Эрэмбэлэх Id</p>
                <input
                    type="text"
                    placeholder="Эрэмбэлэх Id"
                    value={rankId}
                    onChange={(ev) => setRankId(ev.target.value)}
                />
                <div>
                    <div>
                        <p >Хүйс</p>
                        <select value={sex} name="sex" placeholder='sex' onChange={(e) => setSex(e.target.value)}>
                            <option value="Эрэгтэй">Эрэгтэй</option>
                            <option value="Эмэгтэй">Эмэгтэй</option>
                        </select>
                    </div>
                </div>
                <div className="two-line">
                    <button onClick={createNewPost} style={{ marginTop: "5px" }}>Хадгалах</button>
                    <button style={{ color: 'red', borderColor: 'red' }} onClick={closeModal}>Буцах</button>
                </div>
            </div>
        </div>
    );
}