import React, { useEffect, useState } from 'react';
import styles from './post.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddPostModal from '../../Modal/Post/AddPostModal';
import EditPostModal from '../../Modal/Post/EditPostModal';
import DeletePostModal from '../../Modal/Post/DeletePostModal';
import { CiEdit, CiTrash } from "react-icons/ci";

const Posts = () => {
  const [post, setPost] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState({});
  const { id, age } = useParams();
  const navigate = useNavigate();

  const closeModal = () => {
    setModal(false);
    setEditModal(false);
    setDeleteModal(false);
  }

  useEffect(() => {
    axios.get('https://clothes.templateapi.xyz/categories/' + id + '/post/age?sort=-createdAt')
      .then((e) => {
        setPost(e.data.data);
        setIsFetch(true);
      })
      .catch((error) => {
        setIsFetch(true);
        alert(error)
      });
  }, [id, age]);

  return (
    <> {modal ? <AddPostModal closeModal={closeModal} /> : null}
      {editModal && data ? <EditPostModal data={data} closeModal={closeModal} /> : null}
      {deleteModal && data ? <DeletePostModal data={data} closeModal={closeModal} /> : null}
      <div className={styles.button_container}>
        <button onClick={() => navigate(-1)} className={styles.chevButton}>
          <FontAwesomeIcon icon={faChevronLeft} className={styles.icon} />
          Буцах
        </button>
      </div>
      <button onClick={() => setModal(true)} className={styles.add_button}>
        <img className={styles.img}
          src={'https://cdn-icons-png.flaticon.com/512/992/992651.png'}
          alt="" />
        <p>Бараа нэмэх</p>
      </button>
      {isFetch === true ? post.length > 0 ?
        <div className={styles.container}>
          {post.filter((ev) => {
            return age === 'test_2' ? ev.test_2?.includes('true')
              : age === 'summary' ? ev.summary?.includes('true')
                : ev.contactEmail?.includes('true')
          }).map((e) => (
            <div className={styles.post}>
              <div className={styles.item}>
                {/* {e.title} */}
                <Link to={`/postdetial/${e._id}`} className={styles.link}>
                  <img className={styles.img}
                    src={e.isHandle === true ?
                      `https://clothes.templateapi.xyz/${e.cover}` :
                      `https://clothes.templateapi.xyz/uploads/${e.title}.jpg`}
                    alt="" />
                  <p className={styles.button}>Энд дараад үнэ, <br />
                    бусад мэдээлэл харна уу</p>
                </Link>
                <button onClick={() => {
                  setEditModal(true);
                  setData(e);
                }} className={styles.add_button} style={{ color: 'orange' }}>
                  <CiEdit size={35} />
                  <p>Бараа засах</p>
                </button>
                <button onClick={() => {
                  setDeleteModal(true);
                  setData(e)
                }
                } className={styles.add_button} style={{ color: 'red' }}>
                  <CiTrash size={35} />
                  <p>Бараа устгах</p>
                </button>
              </div>
            </div>
          ))}
        </div> :
        <div className={styles.fail}>
          <h2>Энэ хуудас хоосон байна</h2>
        </div> :
        <div className={styles.fail}>
          <h2>Уншиж байна...</h2>
        </div>}
    </>
  )
}

export default Posts
